import { computed, ref } from 'vue'
import parametrosListarService from '../../../../services/parametrosListarService'
const useSolicitudesForm = () => {
  const _parametros = ref({
    diaApertura: null,
    diaCierre: null
  })
  const getParametros = computed(() => _parametros.value)

  const _fillParametros = (valueObject = {}) => {
    for (const [key, value] of Object.entries(valueObject)) {
      _parametros.value[key] = value
    }
  }
  const fetchParametros = () => {
    return parametrosListarService().then(({ data }) => {
      _fillParametros(data)
    }).catch(err => {
      console.error(err.message)
    })
  }

  return {
    getParametros,
    fetchParametros
  }
}

export default useSolicitudesForm
